<template>
  <div class="box-container">
    <div class="d-flex justify-space-between flex-wrap mb-5">
      <div class="d-flex align-center">
        <v-avatar tile size="25" class="me-3">
          <v-icon color="primary" size="30">mdi-account-outline</v-icon>
        </v-avatar>
        <h2 class="mb-0">{{ $t("ViewProfile.myProfile") }}</h2>
      </div>
    </div>

    <v-row v-if="!isLoading">
      
      <v-col cols="12" sm="12" md="4" lg="4">
        <base-card class="pa-4 text-center justify-center">
          <h3 class="font-600 primary--text mb-0">
            {{ statistics.cantidadPagosPendientes || 0 }}
          </h3>
          <p class="mb-0 text-12 grey--text text--darken-1">
            {{ $t("ViewProfile.awaitingPayments") }}
          </p>
        </base-card>
      </v-col>

      <v-col cols="12" sm="12" md="4" lg="4">
        <base-card class="pa-4 text-center justify-center">
          <h3 class="font-600 primary--text mb-0">
            {{ statistics.cantidadEnviosEspera || 0 }}
          </h3>
          <p class="mb-0 text-12 grey--text text--darken-1">
            {{ $t("ViewProfile.awaitingShipment") }}
          </p>
        </base-card>
      </v-col>

      <v-col cols="12" sm="12" md="4" lg="4">
        <base-card class="pa-4 text-center justify-center">
          <h3 class="font-600 primary--text mb-0">
            {{ statistics.cantidadEntregasEspera || 0 }}
          </h3>
          <p class="mb-0 text-12 grey--text text--darken-1">
            {{ $t("ViewProfile.awaitingDelivery") }}
          </p>
        </base-card>
      </v-col>

      <v-col cols="12">
        <base-card>
          <div class="pa-4 d-flex justify-space-between flex-wrap">
            <div class="mx-2 my-2">
              <p class="text-sm grey--text text--darken-1 mb-1">
                {{ $t("ViewProfile.firstname") }}
              </p>
              <span>{{ profile.nombre }}</span>
            </div>
            <div class="mx-2 my-2">
              <p class="text-sm grey--text text--darken-1 mb-1">
                {{ $t("ViewProfile.lastname") }}
              </p>
              <span>{{ profile.apellidos }}</span>
            </div>
            <div class="mx-2 my-2">
              <p class="text-sm grey--text text--darken-1 mb-1">
                {{ $t("ViewProfile.email") }}
              </p>
              <span>{{ profile.mail }}</span>
            </div>
            <div class="mx-2 my-2">
              <p class="text-sm grey--text text--darken-1 mb-1">
                {{ $t("ViewProfile.phone") }}
              </p>
              <span>{{ profile.telefono }}</span>
            </div>
          </div>
        </base-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/**
 * CORRECCIONES:
 * 1. SE CORRIGE ESTILO DE LAS TARJETAS
 * 2. SE REMUEVE TARJETA DE ORDENES QUE DUPLICA INFORMACION VISUALIZADA
 * 3. SE AGREGA FUNCION LOCAL PARA OBTENER INFORMACION DE PERFIL 
 */
import axios from 'axios';
import _ from 'lodash';

export default {
  data() {
    return {
      profile: {},
      statistics: {},
      isLoading: true,
    };
  },
  mounted() {
    this.fetchProfile();
  },
  methods: {
    fetchProfile(){

      this.isLoading = true;

      axios.get('api/v1/profile')
        .then((res) => {

          // SE OBTIENE EL PERFIL DEL USUARIO DE MANERA SEGURA
          const profile = _.get(res, 'data.data', {});

          // ESTABLECE LA INFORMACION DEL USUARIO EN VARIABLE REACTIVA
          this.profile = profile;
        })
        .catch(() => {

          this.profile = {};
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },
};
</script>